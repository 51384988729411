<template>
  <ResultPageView></ResultPageView>
</template>

<script>
import ResultPageView from '../view/ResultPageView.vue'

export default {
  components: {
    ResultPageView
  }
}
</script>