<template>
  <TopPageView />
</template>

<script>
import TopPageView from '../view/TopPageView'
export default {
  name: 'TopPage',
  components: {
    TopPageView
  }
}
</script>
