<template>
  <LogInPageView />
</template>

<script>
import LogInPageView from '../view/LogInPageView.vue'
export default {
  name: 'TopPage',
  components: {
    LogInPageView
  }
}
</script>
