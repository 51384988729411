<template>
  <SignUpPageView />
</template>

<script>
import SignUpPageView from "../view/SignUpPageView.vue";

export default {
  components: {
    SignUpPageView
  }
}
</script>
