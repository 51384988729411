<template>
  <MyPageView />
</template>

<script>
import MyPageView from "../view/MyPageView.vue";

export default {
  components: {
    MyPageView
  }
}
</script>
