<template>
  <FinishRoundView />
</template>

<script>
import FinishRoundView from '../view/FinishRoundView.vue'
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Finish Round',
  components: {
    FinishRoundView
  }
}
</script>
