<template>
  <ProblemPageView></ProblemPageView>
</template>

<script>
import ProblemPageView from '../view/ProblemPageView.vue'

export default {
  components: {
    ProblemPageView
  }
}
</script>