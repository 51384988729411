<template>
  <v-app>
    <router-view />
  </v-app>
</template>

<script></script>

<style>
body {
  width: 100%;
  margin: 0;
}

label {
  display: inline-block;
  text-align: right;
}

input {
  margin: 10px;
}

button {
  margin: 20px;
}

.error {
  color: red;
}
</style>
